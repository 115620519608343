import finnovate1 from "../../../img/finnovate1.PNG";
import techWorkshop2 from "../../../img/tech-workshop2.svg";
import finhack1 from "../../../img/FinhackImg1.PNG";
import finnovate2 from "../../../img/homeVenturesProgram.svg";
import finnovate3 from "../../../img/finnovate3.PNG";
import finhack2 from "../../../img/FinhackImg2.PNG";

export const CARD = [
  {
    key: "finnovate#1",
    img: finnovate1,
    alt: "First image",
  },
  {
    key: "finhack#1",
    img: finhack1,
    alt: "Second image",
  },
  {
    key: "finnovate#2",
    img: finnovate2,
    alt: "Third image",
  },
  {
    key: "techWorkshop#2",
    img: techWorkshop2,
    alt: "Fourth image",
  },
  {
    key: "finnovate#3",
    img: finnovate3,
    alt: "fifth image",
  },
  {
    key: "finhack#2",
    img: finhack2,
    alt: "sixth image",
  },
];
